.page {
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;

  .home {
    align-self: flex-start;
    text-transform: uppercase;
    color: #8dbeff;
    text-transform: uppercase;
    font-family: Fraunces, serif;
    font-weight: bold;
    text-decoration: underline;
  }
}

.container {
  background-color: #fff;
  width: 100%;
  margin: 40px auto;
  display: flex;

  .thumbnail {
    width: 340px;
    height: 340px;
    height: auto;
  }

  .images {
    // width: 540px;
  }

  .squares {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 10px 40px 20px;
    img {
      width: 300px;
      height: 300px;
      margin-bottom: 20px;
    }
  }

  .title {
    padding: 5px 0;
    font-size: 26px;
  }

  .content {
    text-align: left;
    padding: 40px 60px;

    .tags {
      display: flex;
      margin-top: 35px;
    }
    .tag {
      border: 2px solid;
      text-transform: uppercase;
      margin-right: 10px;
      padding: 3px 8px;
      border-radius: 14px;
      font-size: 9px;
      opacity: 0.8;
      font-family: "Fraunces", serif;
      font-weight: bold;
    }

    p {
      font-size: 20px;
      margin: 15px 0;
    }

    h4 {
      color: #8dbeff;
      margin: 50px 0 30px 0;
      font-size: 20px;
      padding: 5px 0;
      width: 185px;
      border-bottom: 2px solid #8dbeff;
    }

    .details {
      display: flex;
      margin: 12px 0;

      div {
        width: 100px;
        margin-right: 10px;
      }
    }

    .map {
      margin: 20px 0;
    }
  }
}

@media only screen and (max-width: 599px) {
  .page {
    width: calc(100% - 20px);
    margin: 0;
    padding: 20px 10px;
  }

  .container {
    margin: 20px 0;
    flex-direction: column;

    .content {
      padding: 10px 20px;
    }

    .squares {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 20px 10px 0px 10px;
      img {
        height: auto;
        width: calc(33% - 5px);
      }
    }
  }
}
