.app {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.app-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 50px;
}

@media only screen and (max-width: 599px) {
  .app-content {
    width: calc(100% - 20px);
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
  }
}

a {
  text-decoration: none;
  color: unset;
}

p,
label,
textarea,
input {
  font-family: Fanwood Text, serif;
}

button {
  font-family: Fraunces, serif;
}

h3,
h2 {
  margin: 20px 0;
}

body {
  background-color: #f8f8f8;
}

p,
ul,
li,
h1,
h2,
h3,
h4,
h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0em;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Fraunces", serif;
}

ul {
  list-style: none;
}

#map {
  height: 325px;
  min-width: 200px;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }
}
