.container {
  width: 925px;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 599px) {
  .container {
    width: 100%;
    margin-left: 0;
    margin-top: 0px;

    a:nth-child(2n) {
      margin-left: 10px;
    }
  }
}
