.container {
  padding: 25px;
  color: #fff;
  display: flex;
  margin-top: 80px;
  background-color: #8dbeff;

  li {
    padding: 5px 10px;
    text-align: left;
  }
}

@media only screen and (max-width: 599px) {
  .container {
    margin-top: 20px;
    padding: 20px 10px;
    font-family: Fanwood Text, serif;
  }
}
