.container {
  .success {
    min-height: 60vh;
  }

  .success,
  .message {
    margin: 60px 0;

    p {
      font-size: 20px;
      max-width: 550px;
      margin: 20px auto;
      font-family: Fanwood Text, serif;
    }
  }

  form {
    min-height: 300px;
    width: 350px;
    margin: 0 auto;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
  }
  textarea,
  input {
    width: 100%;
    padding: 10px 10px;
    font-size: 16px;
    border: none;
  }

  label {
    margin: 5px 0;
    font-size: 18px;
    color: #8dbeff;
    font-family: Fraunces, serif;
  }

  button {
    text-transform: uppercase;
    margin-top: 20px;
    align-self: flex-end;
    background-color: #8dbeff;
    color: #fff;
    border: none;
    padding: 8px 10px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
  }
}

@media only screen and (max-width: 599px) {
  .container {
    .success,
    .message {
      width: 90%;
      margin: 40px auto;
    }

    form {
      width: 90%;
      min-height: 60vh;
    }

    textarea,
    input {
      width: calc(100% - 20px);
      padding: 10px 10px;
    }
  }
}
