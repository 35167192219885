.filters {
  margin-right: 30px;
}

.container {
  width: 300px;
  margin: 10px 0px 10px 0px;
  background-color: #fff;
  border: 1px solid #ededed;
  padding: 10px 10px;
  border-radius: 4px;
  height: auto;
  margin-bottom: 20px;

  label {
    font-family: inter;
  }

  .mobileChevron {
    display: none;
  }

  h3 {
    margin: 15px 0;
  }

  .location,
  .category {
    margin-bottom: 0px;
    display: flex;
    padding: 10px 0;
    input {
      width: 20px;
      accent-color: #8dbeff;
    }
    input:checked {
      border: none;
    }
  }
}

@media only screen and (max-width: 599px) {
  .container {
    width: calc(100% - 20px);
    margin: 0;
    padding: 5px 10px;
    margin-bottom: 15px;

    .mobileChevron {
      display: inline;
      float: right;
      font-size: 16px;
      width: 40px;
    }

    h3 {
      margin: 8px 0;
    }
    .location,
    .category {
      margin-bottom: 0px;
    }
  }

  .filters {
    margin-right: 0px;
    width: 100%;
  }
}
