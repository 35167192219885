.container {
  padding: 25px 15px;
  font-family: system-ui, -apple-system, Inter;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  a {
    text-decoration: none;
  }
}

.headerLogo {
  display: flex;
  align-items: center;

  div {
    font-family: fasthand;
    font-size: 26px;
    font-weight: 500;
    color: #000;
  }

  span {
    font-size: 24px;
    font-weight: 900;
    margin-right: 5px;
    text-transform: uppercase;
    color: #8dbeff;
  }
}

@media only screen and (max-width: 599px) {
  .container {
    padding: 15px;
  }
}
