.container {
  background-color: #fff;
  border-radius: 4px;
  width: 275px;
  border: 1px solid #ededed;
  margin: 10px 15px;

  .thumbnail {
    width: 275px;
    height: 275px;
  }
  img {
    vertical-align: middle;
  }

  .title {
    padding: 13px 0;
  }
}

@media only screen and (max-width: 599px) {
  .link {
    width: calc(50% - 5px);
  }
  .container {
    flex-wrap: wrap;
    width: calc(100% - 0px);
    margin: 5px 0px;

    .thumbnail {
      width: 100%;
      max-height: 150px;
    }

    .title {
      font-size: 12px;
      padding: 10px 0;
    }
  }
}
