.container {
  font-family: "Fraunces", serif;
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;

  p {
    max-width: 600px;
  }
}

@media only screen and (max-width: 599px) {
  .container {
    font-size: 30px;

    margin: 40px 0;

    p {
      max-width: 75%;
    }
  }
}
